import "./blogStyle.css"
import "./blogEntry2.css"

export const Entry2 = () =>{
    return(
        <div className="post">
            <div className="header">
                <h1>
                    Registering To Vote Except The Government UI Designers Are On Strike
                </h1>
                <h3>GWU CSCI 4527</h3>
                <h3>2/8/24 - Owen Wolff</h3>
            </div>

            <div id="intro">
                <div id="introText">
                    <h2 className="centerText">
                        With the rise of complicated and bloated frontend frameworks on the web, websites are getting harder to use
                    </h2>
                    <p className="centerText italic">
                        And I am part of the problem (photosensitive epilepsy warning)
                    </p>
                </div>
                <img src="/blogAssets/entry2/headerImg.png" alt="Angular and React, oh my!" id="headerImage"></img>
            </div>

            <p className="textBody ado">
                As hypocritical as it is for me to say, the over-designing of websites poses a serious issue in creating an internet that is accessible
                to all. Overzealous UI devs run the risk of creating website designs that unintentionally prevent a subset of users from fully interacting
                with interfaces they create. Nuanced means of conveying information such as color coding, visual prompts, and general website flow are
                are only cool if they are inclusive. A really interesting field of research is the use of machine learning to create juiced up screen readers that can catch prompts that would
                otherwise be invisible to traditional screen reader software.
            </p>
            <p className="textBody ado">
                For this blog entry, I'd like to play around with the idea of an ML powered screen reader used in the absolute worst case scenarios. 
                Specifically, I am going to be using an uncensored Mixtral 8x7B model to iteratively mess with the CSS of the California Franchise 
                Tax Board website to make it harder to use, turn the website into a png, then see if GPT-4 can help me find the link 
                to register to vote. Originally I wanted use Ollama-Llava for image recognition but that model was simply not powerful enough.
                <br/>
                <br/>
                <div className="horz"/>
                <br/>
                Technology Used:<br/>
                <ul>
                    <li>GPT-4 vision api</li>
                    <li><a href="https://www.httrack.com/" target="_blank">Httrack</a> to download CA Tax Website</li>
                    <li><a href="https://ollama.ai/library/dolphin-mixtral" target="_blank">Dolphin-Mixtral</a> via Ollama</li>
                    <li>Selenium w/ Chrome web driver to screenshot html (there is probably a better way to do this)</li>
                    <li>Python to hold everything together</li>
                </ul>
            </p>
            <br/>
            <br/>
            <h2 className="section">Procedure Breakdown</h2>
            <div className="sideBy">
                <div className="sideByQuarter">
                    <Html name={"Tax Site"} link={"/blogAssets/entry2/jankedUp/caTax/index.html"}/>
                </div>
                <div className="leftText">
                    <p className="textBody">
                        On the left is a link to a local version of the CA Tax Board website. The link to register to vote can be 
                        found on the bottom right corner of the page. GPT-4 had no issue finding the link on this page.
                    </p>
                    <br/>
                    <p className="textBody">
                        To make the UI uglier and harder to use, I ran the CSS through a locally served Mixtral 8x7b ai model. This pipeline is
                        automated by a little python script, however the speed at which I am able to produce iterations on the CSS is severly limited
                        by the speed of my GPU (45+ mins per iteration). For that reason, I only produced a limited number of runs (~11).
                    </p>
                    <br/>
                    <p className="textBody">
                        The runs were done iteratively, adding onto and tweaking values in the CSS with each pass. When GPT-4 could no longer
                        recognize where the link was, I reverted to a previous version. Unfortunately, due to the unpredicable nature of
                        the input, some of the verification had to be done manually.
                    </p>
                </div>
            </div>
            <h2 className="section">Personal Favorites</h2>
            <div className="sideBy">
                <div className="rightText">
                    <p className="textBody">
                        Here are some of the runs that I thought turned out pretty well. GPT-4 succeeded in finding the link for all of these.
                    </p>
                </div>
                <div className="sideByQuarter">
                    <HtmlSm name={"Run 1"} link={"/blogAssets/entry2/jankedUp/jank1/index.html"}/>
                    <HtmlSm name={"Run 4"} link={"/blogAssets/entry2/jankedUp/jank4/index.html"}/>
                    <HtmlSm name={"Run 6"} link={"/blogAssets/entry2/jankedUp/jank6/index.html"}/>
                </div>
            </div>
            <h2 className="section">The Last Straw(s)</h2>
            <div className="sideBy">
                <div className="sideByQuarter">
                    <HtmlSm name={"Run 8"} link={"/blogAssets/entry2/jankedUp/jank8/index.html"}/>
                    <HtmlSm name={"Run 11"} link={"/blogAssets/entry2/jankedUp/jank11/index.html"}/>
                </div>
                <div className="leftText">
                    <p className="textBody ">
                        "Here is a screenshot from the CA Government tax board. I want to register to vote, help me find the right link."
                    </p>
                    <p className="textBody italic">
                        If you want to register to vote online, you can visit the official California Secretary of State website at registertovote.ca.gov. This is the official site for voter registration in California, and it provides a simple process for you to follow.
                    </p>
                    <div className="horz dualMargin"/>
                    <p className="textBody">
                        GPT-4 did not succeed in helping me register to vote on these sites. If you take a look at the inputs themselves it is little wonder why.
                        For run 8, the icons got so small that they were unreadable against the obnoxious borders. For run 11, the icons got clipped in such a way
                        that it was hard to tell what their purpose was.
                    </p>
                </div>
            </div>
            <h2 className="section">Final Thoughts</h2>
            <p className="textBody">
                It took 8 passes for Mixtral 8x7B to create a website so horrible that GPT-4 could no longer navigate it. 
                Honestly I don't know what this proves. GPT-4 did a great job helping me register to vote so long as the input was not explicitly malicious.
                I am very interested in the field of ML powered screen readers for accesibility software and would love to revisit this idea in the future,
                however this project definitely had some flaws that I would need to address:
                <ul>
                    <li>GPT-4 was tasked with finding a singular specific element</li>
                    <p className="textBody italic">
                        This was a major issue. It made one element the make or break condition, instead of a holistic view of the entire site.
                    </p>
                    <li>LLM's run slow locally</li>
                    <p className="textBody italic">
                        Running 8x7B locally was a major constraint. It made input take way too long to generate.
                    </p>
                    <li>Quantifiable spectrum for poor design</li>
                    <p className="textBody italic">
                        Atrociousness is an abstract concept and is hard to measure. I hoped that doing iterative runs could be a stand in for
                        a quantifiable metric, but the site did not get worse in a linear manner. I would have to rethink my procedure to better measure
                        the awfulness of input.
                    </p>
                    <li>More complex tasks</li>
                    <p className="textBody italic">
                        This goes back to the first issue, but identifying some text in an image on a website is a simple task. I would want to set up
                        some more complicated UI's that could test an algorithm's ability to predict an element's functionality based off of color and layout clues.
                    </p>
                </ul>
                Ultimately I think I failed to capture the spirit of this assignment. I spent a lot of time trying to get everything automated and ended up with 
                an ML and automation project rather than a computer vision project. That said, I had a lot of fun putting all the pieces of this together and
                the truly awful CSS generated by 8x7B made me chuckle.
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
            </p>
            <p id="awful" >
                Is there something wrong with this page?
            </p>
        </div>
    )
}

const Html = (props) => {
    return(
            <div className="blogIcon">
                <a href={props.link} target="_blank" >
                    <div>
                        <img src={require('../resources/html-icon.png')} />
                    </div>
                    <h1>{props.name}</h1>
                </a>
            </div>
    );
};
const HtmlSm = (props) => {
    return(
            <div className="blogIconSm">
                <a href={props.link} target="_blank" >
                    <div>
                        <img src={require('../resources/html-icon.png')} />
                    </div>
                    <h1>{props.name}</h1>
                </a>
            </div>
    );
};