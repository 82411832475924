import "./blogStyle.css"
import "./proposalStyle.css"

export const Proposal = () => {
    return(
        <div className="post">
            <div className="header">
                <h1>
                    Final Project Proposal
                </h1>
                <h3>GWU CSCI 4527</h3>
                <h3>4/11/24 - Owen Wolff</h3>
            </div>

            <div id="intro">
            </div>


            <div class="intro">
                <br/>
                <h1 className="centerText ">
                    Using A Custom Trained YOLOv5 Model For Hand Gesture HCI
                </h1>

                <p className="centerText italic">
                    obligatory minority report refrence
                </p>

                <br/>
            </div>
            <p className="textBody large">
                For my final project in this course, I would like to train a YOLOv5 model to recognize two or three hand gestures
                and use it to enable some basic human-computer interaction. Right now, I am thinking a wave up/down to scroll and 
                a fist to play/pause music.
            </p>
            <h2 className="section">Software Breakdown</h2>
            <ul className="textBody large">
                <li><a href="https://www.hammerspoon.org/">Hammerspoon</a></li>
                <li>AppleScript</li>
                <li>OpenCV</li>
                <li>YOLOv5</li>
            </ul>
            <br/>
            <p className="textBody large">
                There are two distinct parts that I will need to build for this software pipeine. The first is the actual reading and analysis of
                hand gestures via my Mac's builtin webcam. The second part is turning the analyzed hand gestures into interaction on my computer.
                To accomplish the first part, I will be training a custom YOLOv5 model to recognize two or three different classes of hand gestures,
                then continuously run it over input taken from my webcam with OpenCV. If YOLO recognizes a hand gesture, it will fire an event
                (probably in the form of a keyboard macro) via AppleScript. The event will be captured by Hammerspoon (a really cool lua scripted
                automation API for mac) and Hammerspoon will handle the execution of the event.
            </p>
            <br/>
            <h2 className="section">Trials and Tribulations</h2>
            <p className="textBody large">
                Without a doubt, the most difficult part of this project will be training my own YOLOv5 model, specifically collecting adequate data.
                I plan to collect ~30-40 images for each class of gesture, and artificially expand my dataset to ~70 images per gesture using tequniques 
                talked about in lecture. One of my goals for this project is to apply what I know about image masking and color filtering to automatically
                annotate my data with bounding boxes, however this process with have to be closely supervised.
                Once my annotated data has been collected, I will use <a href="https://colab.research.google.com/drive/1gDZ2xcTOgR39tGGs-EZ6i3RTs16wmzZQ">this</a> public 
                Colab Notebeook as a template to execute the training.
            </p>
            <p className="textBody large">
                One other thing that might be a challenge in this project is running my YOLO model over continuous data. I have some experience doing analysis
                on continuous data from a real-time audio visualizer I built a while ago, however it was built in C and extremely lightweight compared to YOLO,
                so speed might be an issue. To address the speed concern, my collection and analysis will most likely have to be threaded in parallel.
            </p>
            <br/>
            <br/>
            <br/>
        </div>
    )
}