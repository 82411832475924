// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./resources/pointer-clickable.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#bigOl{\n    display: block;\n    height: 100%;\n    width: 100%;\n    background-color: white;\n    margin: 0;\n    border: none;\n}\n#titleImage{\n    height: 40%;\n    display: block;\n    margin-left: auto;\n    margin-right: auto;\n}\n#instructions{\n    height: 50%;\n    display: block;\n}\n#startButton{\n    display: block;\n    cursor: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "), auto;\n    margin-left: auto;\n    margin-right: auto;\n    height: 10%;\n}", "",{"version":3,"sources":["webpack://./src/mingleLauncherStyle.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,YAAY;IACZ,WAAW;IACX,uBAAuB;IACvB,SAAS;IACT,YAAY;AAChB;AACA;IACI,WAAW;IACX,cAAc;IACd,iBAAiB;IACjB,kBAAkB;AACtB;AACA;IACI,WAAW;IACX,cAAc;AAClB;AACA;IACI,cAAc;IACd,qDAAsD;IACtD,iBAAiB;IACjB,kBAAkB;IAClB,WAAW;AACf","sourcesContent":["#bigOl{\n    display: block;\n    height: 100%;\n    width: 100%;\n    background-color: white;\n    margin: 0;\n    border: none;\n}\n#titleImage{\n    height: 40%;\n    display: block;\n    margin-left: auto;\n    margin-right: auto;\n}\n#instructions{\n    height: 50%;\n    display: block;\n}\n#startButton{\n    display: block;\n    cursor: url('./resources/pointer-clickable.png'), auto;\n    margin-left: auto;\n    margin-right: auto;\n    height: 10%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
