import "./blogStyle.css"
import "./blogEntry3.css"
import YoutubeEmbed from "./youtubeEmbed"

export const Entry3 = () => {
    return(
        <div className="post">
            <div className="header">
                <h1>
                    Quantifying Fog Coverage In The Outer Sunset (SF, CA) With <a className="hyperlink" href="https://github.com/facebookresearch/segment-anything">Segment Anything</a>
                </h1>
                <h3>GWU CSCI 4527</h3>
                <h3>2/29/24 - Owen Wolff</h3>
            </div>

            <div id="intro">
                <div id="introText">
                    <br/>
                    <h2 className="centerText">
                        I Grew Up In San Francisco's Outer Sunset
                    </h2>
                    <p className="centerText italic">
                        I love it to death, but it sure does have some good pr people
                        <br/>
                    </p>
                </div>
                    <p className="centerText italic small">
                        deceitful image <a className="italic hyperlink" target="_blank" href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fpacificedgesf.com%2Fneighborhoods%2Fsunset&psig=AOvVaw17BN7F1_1sg3KTWTLRwagi&ust=1709306467877000&source=images&cd=vfe&opi=89978449&ved=0CBMQjRxqFwoTCPCFtPjs0IQDFQAAAAAdAAAAABAR">
                            src
                        </a>
                    </p>
                <img src="/blogAssets/entry3/lies.png" alt="LIES!" id="headerImage"></img>
            </div>

            <p className="textBody large">
                The Outer Sunset is a beautiful neighborhood and I am very lucky to have grown up there. It is a residential district situated 
                between Golden Gate Park and the San Francisco Zoo. Honestly, it's pretty great. One thing that sometimes bothers me though is 
                how it is depicted in pictures on the internet. Most images of the Outer Sunset fail to capture the foggy reality of a neighborhood
                right on the coast. For this blog entry I will be addressing this misconception by quantifying the amount of blue sky showing over the 
                Outer Sunset throughout a whole day.
            </p>
            <p className="textBody large">
                My input was a timelapse I found of the sky over the Outer Sunset on <a href="https://www.youtube.com/watch?v=_40rdAsBtSI" target="_blank" className="hyperlink">YouTube</a>:
            </p>
            <br/>
            <div className="centerContainer">
                <YoutubeEmbed embedId="_40rdAsBtSI" className="center"/>
            </div>
            <br/>
            <h2 className="section">Image Scraping</h2>
            <div className="sideBy">
                <div className="leftText">
                    <p className="textBody large">
                        First off, I trimmed the video to remove the pitch black frames before and after sunset using some video editing software.
                        I also cropped the houses and most of the tree out of the frame.
                        Next, I turned the video into a set of images, which is pretty straightforward with a couple lines of python and the opencv library.
                        Doing this nets me a little less than 4000 images that look something like the image on the right. With that done, we can start to analyze.
                    </p>
                </div>
                <img src="/blogAssets/entry3/sampleFrame.jpg" alt="sample scraped frame" className="frame"/>
            </div>
            <br/>

            <h2 className="section">Naive Test Run</h2>
            <div className="sideBy vertCenter">
                <img src="/blogAssets/entry3/samSample1.png" alt="sample scraped frame" className="frame"/>
                <div className="rightText ">
                    <p className="textBody large">
                        Unfortunately, feeding a frame with some sky peeking out to segment anything (SAM) reveals that SAM
                        is bad at delineating between sky and cloud/fog.
                    </p>
                </div>
            </div>
            <div className="sideBy vertCenter">
                <img src="/blogAssets/entry3/samSample2.png" alt="sample scraped frame" className="frame"/>
                <div className="rightText ">
                    <p className="textBody large">
                        Even providing a point with a remove label on the clouds doesn't really fix this issue.
                    </p>
                </div>
            </div>
            <div className="sideBy vertCenter">
                <img src="/blogAssets/entry3/samSample3.png" alt="sample scraped frame" className="frame"/>
                <div className="rightText">
                    <p className="textBody large">
                        In fact, to get any meaningful output from segment anything, I need to provide a whole smattering of points, both
                        positive and negative.
                    </p>
                </div>
            </div>
            <br/>
            <h2 className="section">Scripting With SAM</h2>
            <div className="sideBy">
                <div className="leftText">
                    <p className="textBody large">
                        Automating segment anything over this input brings up the important question of how to prompt it.
                        I do not want to create a mask over everything because I am trying to find the area that a specific object
                        occupies. Instead, I prompted SAM with the location data of pixels to either include or exclude from the mask.
                        I do this by creating masks over blue-ish and grey-ish areas in the image. The center points of these masks 
                        become positive and negative point prompts. The resulting segmentation looks something like this:
                    </p>
                    <p className="textBody large">
                        Notably, this is not a perfect mask. I could probably get more accurate measurements by simply calculating the
                        blue areas from the preprocessing masks that I create, but where's the fun in that? This mask will be good enough to
                        get a rough estimate.
                    </p>
                </div>
                <img src="/blogAssets/entry3/scriptedSample.png" alt="sample scraped frame" className="frame"/>
            </div>
            <p className="textBody large">
                Getting the "blue percent" of the frame is a simple operation. Since SAM creates a binary mask over the image, all I have to do is 
                take the sum of the mask, divide that by the total pixels in the image, then multiply the quotient by 100. In the above example, 
                the sky is ~36% blue. For my analysis, I used the vit_h (most lightweight) SAM model checkpoint.
            </p>
            <br/>
            <h2 className="section">Results</h2>
            <img src="/blogAssets/entry3/graph.png" alt="graph of results" className="dualMargin fullWide"/>
            <p className="textBody large">
                Here is a graph of my results. Past 6:30 it looks like it started to count the tree in the left of the frame as part of the sky.
                In the video, there is no sky showing at that time. In fact, there is a lot of noise throughout the graph, and this is not the smooth
                progression that was expected. As you can see, this is very noisy data. Ignoring some of the jank, this graph shows a gradual 
                ramp up in blue-ness in the sky in the morining, until it drops off hard at around 3:00.
            </p>
            <h2 className="section">Evaluation of SAM</h2>
            <p className="textBody large">
                Overall, I don't think Segment Anything was well suited to this problem. As I said before, I probably could have gotten
                much more accurate results (a lot faster) if I just manually counted pixels within some blue gradient. 
            </p>
            <div className="sideBy vertCenter">
                <img src="/blogAssets/entry3/bad.png" alt="sample scraped frame" className="frame"/>
                <div className="rightText ">
                    <p className="textBody large">
                        Also, I wasn't fully satisfied with the quality of SAM's mask. Specifically, one big issue was blue-ish noise leaking through the
                        tree on the left of the frame which resulted in bad data.
                    </p>
                </div>
            </div>

            <div className="sideBy vertCenter">
                <img src="/blogAssets/entry3/frame1019.jpg" alt="sample scraped frame" className="frame"/>
                <div className="rightText ">
                    <p className="textBody large">
                        I tried to address this issue by preprocessing my data
                        via boosting contrast, dimming brightness, and color inversion. 
                        The preprocessing made the masking a little more accurate, but it kind of destroyed my input. At that point, it didn't 
                        feel like I was feeding real image data to SAM.
                    </p>
                </div>
            </div>

            <p className="textBody large">
                For my final run, I mitigated this issue a little bit by keeping the contrast boosting and adding additional constraints 
                to the preprocess masks that I applied, however it definitely was not a perfect fix. 
                I don't think Segment Anything was built to create masks over fuzzy boundaries like soft clouds over
                a blue sky. Code for the whole project can be found 
                on <a href="https://github.com/scaboodles/CS4527Proj3/tree/main" className="hyperlink">Github</a> excluding the trimmed video and model checkpoint.
            </p>

            <img src="/blogAssets/entry3/cpuOwie.png" alt="my cpu is in pain" className="dualMargin fullWide"/>
            <p className="textBody large">
                One last notable thing is that (as far as I know) pytorch cannot run on the GPU without CUDA, which cannot be used on Mac. Big props to
                my CPU for toughing out 10 hours of pain for this project.
            </p>
        </div>
    )
}